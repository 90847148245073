import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import classNames from 'classnames';

import {
  AwardAlerts,
  Conflicts,
  MissingQualifications,
  Rejected,
  SaveLoad,
  Statistics,
  UnallocatedAppointments,
} from '~weekly-planner/components/SideBar';

import { selectParams } from '~weekly-planner/selectors';
import { selectCount as selectConflictsCount } from '~weekly-planner/selectors/appointments/conflicts';
import { selectCount as selectMissingQualificationsCount } from '~weekly-planner/selectors/appointments/missingQualifications';
import { selectCount as selectRejectedCount } from '~weekly-planner/selectors/appointments/rejected';
import { selectUnallocatedCount } from '~weekly-planner/selectors/simulate';
import { selectAllChangesCount } from '~weekly-planner/selectors/unsaved';
import { selectAwardCount } from '~weekly-planner/selectors/users';

const AccordionTabs = Object.freeze({
  SAVE_LOAD: '1',
  CONFLICTS: '2',
  REJECTED: '3',
  AWARD_ALERTS: '4',
  STATISTICS: '5',
  QUALIFICATIONS: '6',
  UNALLOCATED: '7',
});

interface ComponentProps {
  onRefresh?: () => void;
}

const SideBar: React.FC<ComponentProps> = ({ onRefresh }) => {
  const { week_start } = useAppSelector(selectParams);
  const unallocatedCount = useAppSelector(selectUnallocatedCount);
  const awardCount = useAppSelector(selectAwardCount);
  const rejectedCount = useAppSelector(selectRejectedCount);
  const missingQualificationsCount = useAppSelector(selectMissingQualificationsCount);
  const changesCount = useAppSelector(selectAllChangesCount);
  const conflictCount = useAppSelector(selectConflictsCount);

  const [openAccordionId, setOpenAccordion] = useState<string>('');

  const toggle = (accordianId: string) => {
    setOpenAccordion(() => (openAccordionId === accordianId ? '' : accordianId));
  };

  const { SAVE_LOAD, CONFLICTS, REJECTED, AWARD_ALERTS, STATISTICS, QUALIFICATIONS, UNALLOCATED } = AccordionTabs;

  const renderAccordionItem = (id: string, header: string, body: React.ReactNode) => {
    const className = classNames({
      expanded: openAccordionId === id,
    });

    return (
      <AccordionItem className={className}>
        <AccordionHeader targetId={id} onClick={() => toggle(id)}>
          {header}
        </AccordionHeader>
        <AccordionBody accordionId={id}>{openAccordionId === id && body}</AccordionBody>
      </AccordionItem>
    );
  };
  const buildTitle = (title: string, count: number | undefined) => {
    if (count && count > 0) return `${title} (${count})`;
    return title;
  };

  return (
    <Accordion open={openAccordionId} toggle={toggle} className="side-bar-container">
      {renderAccordionItem(
        SAVE_LOAD,
        buildTitle('Save/Load', changesCount),
        <SaveLoad weekStart={week_start} onRefresh={onRefresh} />,
      )}
      {renderAccordionItem(CONFLICTS, buildTitle('Conflicts', conflictCount), <Conflicts weekStart={week_start} />)}
      {renderAccordionItem(
        REJECTED,
        buildTitle('Rejected Appointments', rejectedCount),
        <Rejected weekStart={week_start} />,
      )}
      {renderAccordionItem(
        AWARD_ALERTS,
        buildTitle('Award Alerts', awardCount),
        <AwardAlerts weekStart={week_start} />,
      )}
      {renderAccordionItem(STATISTICS, 'Statistics', <Statistics weekStart={week_start} />)}
      {renderAccordionItem(
        QUALIFICATIONS,
        buildTitle('Missing Qualifications', missingQualificationsCount),
        <MissingQualifications weekStart={week_start} />,
      )}
      {renderAccordionItem(
        UNALLOCATED,
        buildTitle('Unallocated Appointments', unallocatedCount),
        <UnallocatedAppointments />,
      )}
    </Accordion>
  );
};

export default SideBar;
