import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';

import { getDayString } from '~libs/dayjs';

import Button from '~components/Button';
import Icon, { faEye } from '~components/Icon';
import InfiniteScroll from '~components/InfiniteScroll';
import Spinner from '~components/Spinner';
import WorkerQualifications from '~weekly-planner/components/Common/WorkerQualifications';
import { MissingQualificationsViewMenu } from '~weekly-planner/components/SideBar/MissingQualifications';

import { getAll as getMissingQualifications } from '~weekly-planner/actions/appointments/missingQualifications';

import { clear, updateParams } from '~weekly-planner/reducers/appointments/missingQualifications';
import { focusWorker } from '~weekly-planner/reducers/weeklyPlanner';

import { selectOptions as selectWeeklyPlannerOptions } from '~weekly-planner/selectors';
import {
  selectHasLoaded,
  selectLoading,
  selectCount as selectMissingQualificationCount,
  selectAll as selectMissingQualifications,
  selectParams,
} from '~weekly-planner/selectors/appointments/missingQualifications';

interface ComponentProps {
  weekStart: string;
}

const MissingQualifications: React.FC<ComponentProps> = React.memo(({ weekStart }) => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);
  const hasLoaded = useAppSelector(selectHasLoaded);
  const appointments = useAppSelector(selectMissingQualifications);
  const count = useAppSelector(selectMissingQualificationCount);
  const { missingQualificationsExtendedQualifications } = useAppSelector(selectWeeklyPlannerOptions);

  const isLoading = loading === 'pending';
  const { limit, week_start } = params;

  // Fetch data on param change
  useEffect(() => {
    if (!isLoading && week_start && limit) {
      dispatch(getMissingQualifications(params));
    }
  }, [params]);

  // Update params on date change
  useEffect(() => {
    if (!hasLoaded || weekStart !== week_start) {
      dispatch(updateParams({ week_start: weekStart, limit: 30 }));
    }
  }, [hasLoaded, weekStart, week_start]);

  // unmount & clear reducer
  useEffect(() => {
    return () => {
      dispatch(clear([]));
    };
  }, []);

  const renderResults = () => {
    return appointments.map(({ client, user, start_time, date, qualification_level }, index) => {
      return (
        <Row key={index} className="underline missing-qualifications-card">
          <Col>
            <div>{client?.full_name}</div>
            <div>
              {getDayString(date)} {start_time}
            </div>
          </Col>
          <Col>{user?.full_name}</Col>
          <Col className="worker-qualifications">
            <WorkerQualifications
              qualifications={qualification_level.filter((item: string) => {
                return user?.qualifications.includes(item) ? null : item;
              })}
              isCondensed={!missingQualificationsExtendedQualifications}
              isWrapped={!missingQualificationsExtendedQualifications}
            />
          </Col>
          <Col sm={1} className="text-center">
            <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user?.id))}>
              <Icon icon={faEye} />
            </Button>
          </Col>
        </Row>
      );
    });
  };

  const loader = <Spinner loading={isLoading} className="p-5" />;
  const endMessage = <p className="no-result">No missing qualifications found</p>;
  const loadMore = () => {
    if (!isLoading && limit === appointments.length) {
      dispatch(updateParams({ ...params, limit: limit + 30 }));
    }
  };

  return (
    <div className="card-container">
      <Container id={'missing-qualifications-scrollable-area'} className="missing-qualifications inner-content limited">
        <div className="nav card-header">
          <MissingQualificationsViewMenu />
        </div>
        <InfiniteScroll
          dataLength={appointments.length}
          loadMore={loadMore}
          hasMore={count !== appointments.length}
          loader={loader}
          endMessage={endMessage}
          scrollableTarget="missing-qualifications-scrollable-area"
        >
          <Row className="fw-bold underline ps-1 flex-nowrap">
            <Col>Appointment</Col>
            <Col>Worker</Col>
            <Col>Missing Qualifications</Col>
            <Col sm={1} />
          </Row>
          {renderResults()}
        </InfiniteScroll>
      </Container>
    </div>
  );
});

MissingQualifications.displayName = 'MissingQualifications';
export default MissingQualifications;
