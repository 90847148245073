import React, { ReactNode } from 'react';
import { ButtonProps } from 'reactstrap';

import Button from './Button';

export interface ComponentProps extends ButtonProps {
  count: number;
  children: ReactNode;
}

const NotificationButton: React.FC<ComponentProps> = ({ count, children, ...props }) => {
  return (
    <Button className="notification-button" {...props}>
      {children}
      {count > 0 && <span className="count">{count}</span>}
    </Button>
  );
};

export default NotificationButton;
