import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { throttle } from 'lodash';

interface InfiniteScrollProps {
  dataLength: number;
  loadMore: () => void;
  hasMore: boolean;
  loader: React.ReactNode;
  endMessage: React.ReactNode;
  scrollableTarget?: string;
  children: React.ReactNode;
  className?: string;
  id?: string;
  throttleTime?: number;
}

/**
 * A component that implements infinite scrolling. It triggers loading of more items when the user scrolls to the bottom.
 * It can be customized to work with any scrollable container, display a loading indicator, and show an end message when all data is loaded.
 *
 * @component
 * @example
 * const App = () => {
 *   const [data, setData] = useState([]);
 *   const [hasMore, setHasMore] = useState(true);
 *   const loadMoreData = () => {
 *     fetchMoreData().then(newData => {
 *       setData(prevData => [...prevData, ...newData]);
 *       if (newData.length === 0) setHasMore(false);
 *     });
 *   };
 *   return (
 *     <InfiniteScroll
 *       dataLength={data.length}
 *       loadMore={loadMoreData}
 *       hasMore={hasMore}
 *       loader={<Loader />}
 *       endMessage={<EndMessage />}
 *       throttleTime={500}
 *     >
 *       {data.map(item => (
 *         <div key={item.id}>{item.content}</div>
 *       ))}
 *     </InfiniteScroll>
 *   );
 * };
 *
 * @param dataLength - The number of items currently loaded.
 * @param loadMore - A function to load more data when needed.
 * @param hasMore - A boolean indicating whether there are more items to load.
 * @param loader - A React element to show while loading more items.
 * @param endMessage - A React element to show when all items are loaded.
 * @param scrollableTarget - Optionally specify an ID of a scrollable container instead of using the default.
 * @param children - The list of items or content to display inside the scrollable container.
 * @param className - Optional class names for the container.
 * @param id - Optional ID for the container.
 * @param throttleTime - Optional throttle time in milliseconds, default is 350ms.
 */
const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(
  (
    {
      dataLength,
      loadMore,
      loader,
      endMessage,
      scrollableTarget,
      children,
      hasMore,
      className,
      id,
      throttleTime = 350,
    },
    ref,
  ) => {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const lastElementRef = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => scrollableContainerRef.current as HTMLDivElement);

    const throttledLoadMore = useCallback(
      throttle(() => {
        if (!isLoading) {
          setIsLoading(true);
          loadMore();
        }
      }, throttleTime),
      [loadMore, throttleTime, isLoading],
    );

    useEffect(() => {
      return () => {
        throttledLoadMore.cancel();
      };
    }, [throttledLoadMore]);

    useEffect(() => {
      setIsLoading(false);
    }, [dataLength]);

    useEffect(() => {
      let container: HTMLElement | null = null;

      if (scrollableTarget) {
        container = document.getElementById(scrollableTarget) as HTMLElement;
      } else {
        container = scrollableContainerRef.current;
      }

      if (!container) {
        console.error('[useEffect] Scrollable target or scrollable container not found!');
        return;
      }

      const options = {
        root: container,
        threshold: 0,
      };

      //disabled as IntersectionObserverCallBack is a dom global
      //eslint-disable-next-line
      const handleIntersection: IntersectionObserverCallback = ([entry]) => {
        if (entry.isIntersecting && hasMore) {
          throttledLoadMore();
        }
      };

      if (lastElementRef.current) {
        observerRef.current = new IntersectionObserver(handleIntersection, options);
        observerRef.current.observe(lastElementRef.current);
      }

      return () => {
        if (observerRef.current && lastElementRef.current) {
          observerRef.current.unobserve(lastElementRef.current);
        }
      };
    }, [hasMore, dataLength, scrollableTarget, throttledLoadMore]);

    return (
      <div
        ref={scrollableTarget ? undefined : scrollableContainerRef}
        className={className}
        id={id}
        style={{
          overflowY: scrollableTarget ? 'auto' : undefined,
        }}
      >
        {children}

        {hasMore && (
          <>
            <div ref={lastElementRef} style={{ height: '1px', backgroundColor: 'transparent' }} />
            {isLoading && loader}
          </>
        )}

        {!hasMore && endMessage}
      </div>
    );
  },
);

InfiniteScroll.displayName = 'InfiniteScroll';

export default InfiniteScroll;
