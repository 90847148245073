import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';

import { WeeklyPlannerDateRangeOptions as RangeOptions } from '~constants/maps';

import { dateInputFieldFormat, weeklyPlannerDayDisplayFormat } from '~libs/dayjs';
import { LoadingState } from '~libs/reduxUtils';
import { Client, Worker } from '~weekly-planner/lib/common';

import ContainerPanel from '~components/ContainerPanel';
import InfiniteScroll from '~components/InfiniteScroll';
import Spinner from '~components/Spinner';
import { Row } from '~weekly-planner/components/Main/Daily';
import FocusBar from '~weekly-planner/components/Main/FocusBar';

import { update, updateParams } from '~weekly-planner/reducers/weeklyPlanner';

import { selectDate, selectFocusedAppointment, selectParams } from '~weekly-planner/selectors';

interface ComponentProps {
  headerCell: React.FunctionComponent<any>;
  rows: Array<Worker | Client>;
  focusId?: number | null;
  loadingState: LoadingState | null;
}

const View: React.FC<ComponentProps> = ({ headerCell: HeaderCell, rows, focusId, loadingState }) => {
  const [isMousedown, setIsMousedown] = useState<boolean>(false);
  const [startX, setStartX] = useState(0);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);

  const dispatch = useAppDispatch();
  const { week_start, limit } = useAppSelector(selectParams);
  const date = useAppSelector(selectDate);
  const isLoading = loadingState === 'pending';

  // most recent Monday
  const weekStart = dayjs(week_start).weekday(0);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => weekStart.add(i, 'day'));

  const focused = useAppSelector(selectFocusedAppointment);
  const { appointment: focusedAppointment } = focused || {};
  const { id: focusAppointmentId } = focusedAppointment || {};
  const focusedRow = rows?.find(({ id }) => Number(id) === focusId);

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const middleOfDayRef = useRef<HTMLDivElement>(null);

  const className = classNames('daily-view-container', {
    loading: isLoading,
  });

  const wrapperClassName = classNames('daily-view-wrapper', { dragging: isMousedown });
  const isCollapsed = isNaN(Number(focusAppointmentId));

  const handleMouseMove = (event: any) => {
    if (isMousedown && contentWrapperRef.current) {
      // Calculate the horizontal movement
      const deltaX = startX - event.clientX;

      const newScrollPosition = initialScrollLeft + deltaX;

      // Update scroll position
      contentWrapperRef.current.scrollLeft = newScrollPosition;
    }
  };

  // Handle the mouse down event to start dragging
  const handleMouseDown = (event: React.MouseEvent) => {
    if (contentWrapperRef) {
      document.addEventListener('mouseup', handleMouseUp);
      setIsMousedown(true);
      setStartX(event.clientX); // Track initial mouse position
      setInitialScrollLeft(contentWrapperRef?.current?.scrollLeft ?? 0); // Track initial scroll position
    }
  };

  // Handle the mouse up or mouse leave event to stop dragging
  const handleMouseUp = () => {
    setIsMousedown(false);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (middleOfDayRef?.current) {
      middleOfDayRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, []);

  const setView = (value: Dayjs) => {
    dispatch(
      updateParams({
        week_start: value.format(dateInputFieldFormat),
        week_end: value.add(1, 'day').format(dateInputFieldFormat),
      }),
    );
    dispatch(update({ date: value.format(dateInputFieldFormat), rangeType: RangeOptions.DAILY }));
  };

  const renderHeader = (days: Dayjs[]) => {
    return (
      <>
        <div className="header-cell title">View:</div>
        {days.map((day) => {
          const className = classNames('header-cell', {
            selected: day.isSame(date, 'day'),
          });

          return (
            <div key={day.toString()} className={className}>
              <span className="clickable" onClick={() => setView(day)}>
                {day.format(weeklyPlannerDayDisplayFormat)}
              </span>
            </div>
          );
        })}
      </>
    );
  };

  const renderTimeline = () => {
    const className = classNames('timeline-wrapper', { dragging: isMousedown });
    return (
      <div className={className} onMouseDown={handleMouseDown}>
        <div className="timeline-header">Timeline</div>
        <div className="timeline-content">
          {[...Array(24)].map((_, index) => (
            <div key={index} className="hour-marker" ref={index === 12 ? middleOfDayRef : null}>
              {`${index}:00`}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderFocusedRow = (row: Worker | Client) => {
    return (
      <div className="focus-row">
        <Row
          key={`${row.id}-0`}
          headerCell={<HeaderCell data={row} date={dayjs(week_start)} />}
          data={row}
          date={dayjs(date)}
          events={row.events[date]}
        />
      </div>
    );
  };

  const renderRows = (rows: Array<Worker | Client>, focusedRow?: Worker | Client) => {
    return rows
      ?.filter(({ id }) => focusedRow?.id !== id)
      .map((row, index) => {
        let gridRow = index + 1;
        if (!focusedRow) gridRow += 1;
        const style: CSSProperties = {
          gridRow,
        };
        return (
          <Row
            key={`${row.id}-${index}`}
            headerCell={<HeaderCell data={row} date={dayjs(week_start)} style={style} />}
            data={row}
            date={dayjs(date)}
            events={row.events[date]}
            style={style}
          />
        );
      });
  };

  const loader = <Spinner loading={isLoading} className="p-5" />;
  const endMessage = 'End of ...';
  const loadMore = () => {
    if (!isLoading && limit === rows.length) {
      dispatch(updateParams({ limit: limit + 30 }));
    }
  };

  return (
    <ContainerPanel
      className={wrapperClassName}
      isCollapsed={isCollapsed}
      canCollapse={false}
      canResize={false}
      sidePanel={<FocusBar />}
    >
      <div ref={scrollableContainerRef} className={className} onMouseMove={handleMouseMove}>
        <div className="header-wrapper">{renderHeader(daysOfWeek)}</div>
        <InfiniteScroll
          dataLength={rows.length}
          ref={contentWrapperRef}
          loadMore={loadMore}
          className="content-wrapper"
          hasMore={true}
          loader={loader}
          endMessage={endMessage}
        >
          {renderTimeline()}
          {focusedRow && renderFocusedRow(focusedRow)}

          {renderRows(rows, focusedRow)}
        </InfiniteScroll>
      </div>
    </ContainerPanel>
  );
};

export default View;
