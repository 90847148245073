import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import ContainerBanner from '~components/ContainerBanner';
import { faTriangleExclamation } from '~components/Icon';

import { selectOverdueInvoiceAnnouncement, updateOverdueInvoiceAnnouncement } from '~main/reducers/app';

import { selectCompanySettings } from '~main/selectors/login';

const IS_OVERDUE_TAG = 'overdue';

const AccountsOverdueBanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectCompanySettings);
  const config = useAppSelector(selectOverdueInvoiceAnnouncement);

  const setConfig = (data: any) => {
    dispatch(updateOverdueInvoiceAnnouncement(data));
  };

  const onClear = () => setConfig({ ...config, isClosed: true });

  const showBanner =
    ((settings?.SYSTEM_ACCESS_INVOICE_DUE ?? '') as string).toLowerCase() === IS_OVERDUE_TAG && !config.isClosed;

  const header = 'Accounts Overdue Notice';
  const messages = [
    <p key={1}>
      {"Please contact Turnpoint Software in regards to your company's overdue system access invoices by emailing: "}
      <a href="mailto:accounts@turnpointcare.com.au">accounts@turnpointcare.com.au</a>
    </p>,
  ];
  return (
    <>
      {showBanner && (
        <ContainerBanner
          title={header}
          messages={messages}
          onClear={onClear}
          className="system-announcement overdue"
          icon={faTriangleExclamation}
          iconSize="2x"
        />
      )}
    </>
  );
};

export default AccountsOverdueBanner;
