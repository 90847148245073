import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import ContainerHeader from '~components/ContainerHeader';

import { widgets } from '~dashboard/forms/widgets';

import { setIsDesktopView } from '~main/reducers/app';

import { selectAuthenticatedUser, selectUserTags } from '~main/selectors/login';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthenticatedUser);
  const tags = useAppSelector(selectUserTags);
  const { company } = user ?? {};

  useEffect(() => {
    dispatch(setIsDesktopView(false));
  }, []);

  const renderSections = (tags: string[]) => {
    // Loop through all sections, check permissions and display.
    return widgets.map(({ id, authTag, component: Component }) => {
      if (!tags.includes(authTag)) return null;
      return <Component key={id} user={user} />;
    });
  };

  return (
    <div className="dashboard p-2">
      <ContainerHeader className="info">Home - {company?.name}</ContainerHeader>
      {renderSections(tags)}
    </div>
  );
};

export default Dashboard;
