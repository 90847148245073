import React, { useState } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';

import ContainerHeader from '~components/ContainerHeader';
import Snapshot from '~dashboard/components/Snapshot';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const Snapshots: React.FC = () => {
  // Placeholder for API response
  const exampleReturn = [
    {
      id: 'rejected', // id of field to match to constant
      type: 'number', // number, percentage or graph
      value: 24, // value to display
    },
    {
      id: 'accepted',
      type: 'number',
      value: 96,
    },
    {
      id: 'declined',
      type: 'number',
      value: 67,
    },
    {
      id: 'pending',
      type: 'number',
      value: 200,
    },
    {
      id: 'appointments_today',
      type: 'number',
      value: 100,
    },
  ];

  const [layout, setLayout] = useState<Layout[]>(
    exampleReturn.map(({ id }, index) => ({
      i: id,
      x: (index % 3) * 4,
      y: Math.floor(index / 3) * 2,
      w: 4,
      h: 2,
    })),
  );

  const onLayoutChange = (newLayout: Layout[]) => {
    setLayout(newLayout);
  };

  return (
    <div className="snapshots">
      <ContainerHeader>Snapshots</ContainerHeader>
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={40}
        width={1200}
        onLayoutChange={onLayoutChange}
        isResizable
        isDraggable
      >
        {exampleReturn.map(({ id, value }) => {
          const layoutItem = layout.find((item) => item.i === id);
          return (
            <div key={id} data-grid={layoutItem} className="snapshot-card">
              <Snapshot id={id} value={value} />
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default Snapshots;
