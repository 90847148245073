export const fields = [
  {
    id: 'rejected',
    label: 'Rejected Appointments',
    authTag: '-',
  },
  {
    id: 'accepted',
    label: 'Accepted Appointments',
    authTag: '-',
  },
  {
    id: 'pending',
    label: 'Pending Appointments',
    authTag: '-',
  },
  {
    id: 'declined',
    label: 'Declined Appointments',
    authTag: '-',
  },
  {
    id: 'appointments_today',
    label: 'Appointments Today',
    authTag: '-',
  },
];

//TODO - Move into api as constant list of info.
