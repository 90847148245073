import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import ContainerBanner from '~components/ContainerBanner';
import { faBell } from '~components/Icon';

import { selectAnnouncementConfig, updateConfig } from '~main/reducers/app';

import { selectSystemConfig } from '~main/selectors/login';

const AnnouncementBanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const { content } = useAppSelector(selectSystemConfig) ?? {};
  const config = useAppSelector(selectAnnouncementConfig);

  const setConfig = (data: any) => {
    dispatch(updateConfig(data));
  };

  const { heading, text = '' } = content ?? {};

  useEffect(() => {
    if (text && text !== config.text) {
      setConfig({ isClosed: false, text: text });
    }
  }, [text]);

  if (!content) return null;

  // Only allow the announcement to be closed if the configuration allows it. Can be collapsible or persistent
  const onClear = () => setConfig({ ...config, isClosed: true });

  return (
    <>
      {!config.isClosed && (
        <ContainerBanner
          title={heading}
          messages={[text]}
          className="system-announcement"
          icon={faBell}
          iconSize="2x"
          onClear={onClear}
        />
      )}
    </>
  );
};

export default AnnouncementBanner;
