import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import Icon, { faBullhorn } from '~components/Icon';
import NotificationButton from '~components/NotificationButton';

import {
  selectAnnouncementConfig,
  selectOverdueInvoiceAnnouncement,
  updateConfig,
  updateOverdueInvoiceAnnouncement,
} from '~main/reducers/app';

import { selectSystemConfig } from '~main/selectors/login';

const STICKY_TAG = 'Fixed';

const AnnouncementMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { content } = useAppSelector(selectSystemConfig) ?? {};
  const config = useAppSelector(selectAnnouncementConfig);
  const overdueConfig = useAppSelector(selectOverdueInvoiceAnnouncement);

  const setConfig = (data: any) => dispatch(updateConfig(data));

  const { isClosed, text } = config;
  const { text: contentText, status } = content ?? {};
  const isStickyAnnouncement = contentText === text && isClosed && status === STICKY_TAG;
  const isOverdueClosed = overdueConfig?.isClosed;

  const count = (isStickyAnnouncement ? 1 : 0) + (isOverdueClosed ? 1 : 0);

  const onUnclear = () => {
    setConfig({ ...config, isClosed: false });
    dispatch(updateOverdueInvoiceAnnouncement({ ...overdueConfig, isClosed: false }));
  };

  return (
    <NotificationButton count={count} onClick={onUnclear}>
      <Icon icon={faBullhorn} />
    </NotificationButton>
  );
};

export default AnnouncementMenu;
