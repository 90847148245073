import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import Spinner from '~components/Spinner';

import { fields as snapshotFields } from '~dashboard/forms/snapshots';

interface ComponentProps {
  id: string;
  value: any;
}

const Snapshot: React.FC<ComponentProps> = ({ id, value }) => {
  const [isLoading, setLoading] = useState(true);
  const formField = snapshotFields.find((field) => id === field.id);

  useEffect(() => {
    // random delay for API / loading demo
    const delay = Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000;
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Card>
        <CardHeader>{formField?.label}</CardHeader>
        {isLoading ? <Spinner size={10} loading={isLoading} className="m-2" /> : <CardBody>{value}</CardBody>}
      </Card>
    </div>
  );
};

export default Snapshot;
