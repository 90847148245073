import React, { CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';

import { WeeklyPlannerDateRangeOptions as RangeOptions } from '~constants/maps';

import { dateInputFieldFormat, weeklyPlannerDayDisplayFormat } from '~libs/dayjs';
import { LoadingState } from '~libs/reduxUtils';
import { Client, Worker } from '~weekly-planner/lib/common';

import InfiniteScroll from '~components/InfiniteScroll';
import Spinner from '~components/Spinner';

import { update, updateParams } from '~weekly-planner/reducers/weeklyPlanner';

import { selectParams } from '~weekly-planner/selectors';

import { Row } from './index';

interface ComponentProps {
  headerCell: React.FunctionComponent<any>;
  rows: Array<Worker | Client>;
  focusId?: number | null;
  loadingState: LoadingState | null;
}

const View: React.FC<ComponentProps> = ({ headerCell: HeaderCell, rows, focusId, loadingState }) => {
  const dispatch = useAppDispatch();
  const { week_start, limit } = useAppSelector(selectParams);

  const isLoading = loadingState === 'pending';
  const weekStart = dayjs(week_start).weekday(0);
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => dayjs(weekStart).add(i, 'day'));
  const date = dayjs(week_start);
  const focusedRow = rows?.find(({ id }) => Number(id) === focusId);

  const setView = (value: Dayjs) => {
    dispatch(updateParams({ week_start: value.weekday(0).format(dateInputFieldFormat) }));
    dispatch(update({ date: value.format(dateInputFieldFormat), rangeType: RangeOptions.DAILY }));
  };

  const containerClassNames = classNames('weekly-view-container', {
    loading: isLoading,
    'initial-load': rows.length < 1,
  });

  const renderHeader = (days: Dayjs[]) => {
    return (
      <>
        <div className="header-cell">Care Worker</div>
        {days.map((day) => (
          <div key={day.toString()} className={`header-cell ${day.isSame(dayjs(), 'day') ? 'today' : ''}`}>
            <span className="clickable" onClick={() => setView(day)}>
              {day.format(weeklyPlannerDayDisplayFormat)}
            </span>
          </div>
        ))}
      </>
    );
  };

  const renderFocusedRow = (days: Dayjs[], row: Worker | Client) => {
    return (
      <div className="focus-row">
        {renderHeader(days)}
        <Row
          key={`${row.id}-0`}
          headerCell={<HeaderCell data={row} date={date} />}
          data={row}
          days={days}
          date={date}
        />
      </div>
    );
  };

  const renderRows = (rows: Array<Worker | Client>, focusedRow?: Worker | Client) => {
    return rows
      ?.filter(({ id }) => focusedRow?.id !== id)
      .map((row, index) => {
        let gridRow = index + 1;
        if (!focusedRow) gridRow += 1;

        const style: CSSProperties = {
          gridRow,
          gridColumn: 1,
        };
        return (
          <Row
            key={`${row.id}-${index}`}
            headerCell={<HeaderCell data={row} date={date} style={style} />}
            data={row}
            days={daysOfWeek}
            date={dayjs(week_start)}
            style={style}
          />
        );
      });
  };

  const loader = <Spinner loading={isLoading} className="p-5" />;
  const endMessage = 'End of ...';
  const loadMore = () => {
    if (!isLoading && limit === rows.length) {
      dispatch(updateParams({ limit: limit + 30 }));
    }
  };
  return (
    <div className="weekly-view-wrapper">
      {focusedRow && renderFocusedRow(daysOfWeek, focusedRow)}
      <InfiniteScroll
        dataLength={rows.length}
        className={`${containerClassNames}`}
        loadMore={loadMore}
        hasMore={true}
        loader={loader}
        endMessage={endMessage}
      >
        {!focusedRow && renderHeader(daysOfWeek)}
        {renderRows(rows, focusedRow)}
      </InfiniteScroll>
    </div>
  );
};

export default View;
